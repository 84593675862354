@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

body {
    background: rgba(3, 65, 70, 0.9);
}

.Layout404 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Layout404Image{
    width: 600px;
    margin: 0 32px;
}

* {
    font-family: 'Sora';
}

.Layout404Title{
    margin: 32px 0 0;
}

.Layout404SubTitle{
    margin: 16px 0 0;
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
}

.imageLogo {
    width: 200px;
    z-index: 10;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 10;
}

.signUpButtonFormat {
    background-color: rgb(3, 123, 249) !important;
    font-weight: 400 !important;
    cursor: pointer;
}

.signUpButtonFormat:hover {
    filter: brightness(150%);
}

.logInButtonFormat {
    font-weight: 400 !important;
    color: white !important;
    cursor: pointer;
}

.logInButtonFormat:hover {
    color: white !important;
    cursor: not-allowed;
}


.videoOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(3, 65, 70, 0.9);
    z-index: 1;
}

.backgroundVideo {
    position: absolute;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
}

.videoContainer {
    display: flex;
}

.mainContentContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-left: 9vw;
    padding-right: 5vw;
    z-index: 10;
    gap: 20px;
}

@media (max-width: 800px) {
    .mainContentContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 9vw;
        padding-right: 9vw;
        z-index: 10;
        gap: 20px;
    }
}

.mainContentTextContainer {
    display: flex;
    flex-direction: column;
}

.mainContentTitle {
    color: rgb(233, 249, 252);
    font-weight: 600;
    line-height: 70px;
    font-size: 2.9vw;
    margin: 6vw 0 0 0;
}

.mainContentImage {
    width: 100%;
    max-height: 80vh;
    object-fit: contain;
    aspect-ratio: 1/1;
}

.mainContentSubTitle {
    color: rgb(233, 249, 252);
    font-weight: 200;
    line-height: 50px;
    font-size: 1.9vw;
    width: 90%;
}

.mainContentCTAText {
    color: rgb(233, 249, 252);
    font-size: 1.6vw;
    font-weight: 400;
    margin-bottom: 0;
}

.mainContentSelectText {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.mainContentStaticText {
    color: rgb(233, 249, 252);
    font-size: 0.9vw;
    align-self: center;
    margin: 10px 0 0 0;
}

.mainContentSubmitButton:hover {
    filter: brightness(150%);
}

.mainContentTextArea {
    display: flex;
    flex-direction: row;
}

.mainContentHelperText {
    color: white;
    border-bottom: 1px dashed white;
    align-self: flex-start;
    font-size: 0.8vw;
}

.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modalOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 11; 
}

.modalSignUpContentContainer {
    background-color: white;
    padding: 1.5vw 2vw 2vw 2vw;
    z-index: 12;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.modalSignUpTitle {
    color: "#2f2f2f";
    font-weight: 600;
    font-size: 2vw;
    text-align: left;
    margin: 0;
}

.modalSignUpBody {
    color: "#2f2f2f";
    margin: 12px 0;
    font-weight: 400;
    font-size: 1vw;
}

.modalSignUpCTA {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 12px 0;
}

.closeIcon {
    font-size: 1.1vw;
    align-self: flex-end;
    cursor: pointer;
}

.modalSignUpListItem {
    margin: 8px 0;
}

.userMessage {
    margin: 0;
    padding: 20px;
    background-color: #F2F9FF;
    color: #0872DF;
    border: 0.1px solid #0872DF;
    border-radius: 30px;
    align-self: flex-end;
    max-width: 50%;
    font-weight: 500;
    font-size: 0.8vw;
}

.openAIMessage {
    padding: 20px;
    background-color: #F2F4F5;
    color: #5F5F5F;
    border-radius: 30px;
    align-self: flex-start;
    font-weight: 500;
    font-size: 0.8vw;
    white-space: pre-wrap;
}

.openAIMessage p {
    margin: 0;
}

.modalSendMessageContentContainer{
    background-color: white;
    padding: 1.5vw 2vw 2vw 2vw;
    z-index: 12;
    border-radius: 20px;
    width: 60%;
    max-width: 60%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    gap: 20px;
    overflow: scroll;
}

.interviewChoiceButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border: 2px solid #5f5f5f;
    background-color: white;
    border-radius: 30px;
    padding: 5px 10px;
    margin: 0;
    cursor:not-allowed;
}

.interviewChoiceButtonActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border: 2px solid #5f5f5f;
    background-color: #5f5f5f;
    border-radius: 30px;
    padding: 5px 10px;
    margin: 0;
    color: white;
    cursor:not-allowed;
}

.interviewChoiceButton p, .interviewChoiceButtonActive p{
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 0.8vw;
}

.interviewChoiceContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 0;
}

.openAIMessageContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 60%;
}

.openAIIcon {
    max-width: 100%;
    background-color: #F2F4F5;
    color: #5F5F5F;
    flex-shrink: 0;
    border-radius: 40px;
    font-size: 40px;
    padding: 10px;
}

.openAIMessageTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: #F2F4F5;
    color: #5F5F5F;
    border-radius: 30px;
}

.openAIMessageTextFormat {
    color: #5F5F5F;
    border-radius: 30px;
    font-weight: 500;
    font-size: 0.8vw;
    margin: 5px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.openAIMessageInsightContainer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    max-width: 60%;
}

.openAIMessageInsightIcon {
    max-width: 100%;
    background-color: #f2d8ff;
    color: #9708df;
    flex-shrink: 0;
    border: 0.2px dashed #9708df;
    border-radius: 40px;
    font-size: 40px;
    padding: 8px;
}

.openAIMessageInsightTextContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    background-color: #f2d8ff;
    border: 0.5px dashed #9708df;
    border-radius: 30px;
}

.openAIMessageInsightTextFormat {
    color: #9708df;
    border-radius: 30px;
    font-weight: 500;
    font-size: 0.8vw;
    margin: 5px 0;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.ActionIconsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.ActionIconsInsight {
    max-width: 100%;
    background-color: #F2F9FF;
    color: #0872DF;
    flex-shrink: 0;
    border: 0.2px solid #0872DF;
    border-radius: 40px;
    font-size: 40px;
    padding: 4px;
    cursor:not-allowed
}

.ActionIconsInsightActive {
    max-width: 100%;
    background-color: #0872DF;
    color: #F2F9FF;
    flex-shrink: 0;
    border: 0.2px solid #0872DF;
    border-radius: 40px;
    font-size: 40px;
    padding: 4px;
    cursor:not-allowed
}

.openAIMessageConversationContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.donutGraph {
    cursor: not-allowed;
}
